import styles from "./User.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { supabase } from "../supabase/client";
import { toast } from "react-toastify";
import { format } from "date-fns";
import Loader from "../components/ui/elements/custom/Loader";
import { useUser } from "../auth/userContext";
import isPermitted from "../utils/isPermitted";
import { IoChevronForwardSharp } from "react-icons/io5";
import formatDateForUser from "../utils/formatDateForUser";

function User() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const params = useParams();

  const { authSession, accessToken } = useUser();

  const fetchUser = async (id) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("users")
        .select(
          "created_at, first_name, last_name, full_name, email, auth_user_id, last_sign_in, updated_at, active, role_id(role), user_hotel (hotels ( code))"
        )
        .eq("id", id)
        .single();

      if (error) {
        toast.error(
          () => (
            <>
              <p>Failed to retrieve user information!</p>
              <p className={styles.info}>Reason: {error.message}</p>
            </>
          ),
          {
            toastId: "errortoast",
          }
        );
        navigate("/users");
        return;
      }

      if (data.auth_user_id === authSession.user.id) {
        toast.error("Wrong operation", {
          toastId: "errortoast",
        });
        navigate("/users");
        return;
      }

      setUser(data);
    } catch (error) {
      // log error in errors table
      toast.error(
        () => (
          <>
            <p>Failed to retrieve user information!</p>
            <p className={styles.info}>Reason: {error.message}</p>
          </>
        ),
        {
          toastId: "errortoast",
        }
      );
      navigate("/users");
    }
    setIsLoading(false);
  };

  const deleteUser = async () => {
    const permitted = await isPermitted(user.role_id, authSession);
    if (!permitted) {
      toast.error(
        `You are not permitted to delete ${user.role_id.role} accounts.`
      );

      return;
    }

    // Display the toast with promise

    const url =
      import.meta.env.MODE === "production"
        ? import.meta.env.VITE_PRD_SERVER_URL
        : import.meta.env.VITE_DEV_SERVER_URL;

    toast.promise(
      fetch(`${url}/delete-user`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ authUserId: user.auth_user_id }), // Send the user ID to delete
      })
        .then((response) => {
          if (!response.ok) throw new Error("Failed to delete user");
          return response.json();
        })
        .then((data) => data),
      {
        pending: `Deleting ${user.full_name}...`,
        success: {
          render() {
            // Navigate back to user list
            navigate("/users");
            // Return the success message
            return `${user.full_name} deleted successfully!`;
          },
        },
        error: {
          render({ data }) {
            // Return the error message
            return (
              <>
                <p>Deleting {user.full_name} failed!</p>
                <p className={styles.info}>Reason: {data.message}</p>
              </>
            );
          },
        },
      }
    );
  };

  useEffect(() => {
    if (params.id) {
      fetchUser(params.id);
    }
  }, [params.id, navigate]);

  return (
    <>
      <div className={styles.main_container}>
        {isLoading ? (
          <Loader message="Loading user information..." size="large" />
        ) : (
          <>
            <h3 className={styles.page_title}>
              Users <IoChevronForwardSharp />
              {`${user.first_name} ${user.last_name}`}
            </h3>

            <div className={styles.form_container}>
              <h3 className={styles.bold}>Details</h3>
              <ul className={styles.user_details}>
                <li className={styles.detail}>
                  <span className={styles.bold}>Created:</span>{" "}
                  <span>{formatDateForUser(user.created_at)}</span>
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Last Login:</span>{" "}
                  <span>
                    {!user.last_sign_in
                      ? "Never"
                      : formatDateForUser(user.last_sign_in)}
                  </span>
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Last Active:</span>{" "}
                  <span>
                    {!user.updated_at
                      ? "Never"
                      : formatDateForUser(user.updated_at)}
                  </span>
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Email:</span>{" "}
                  <span>{user.email}</span>
                </li>

                <li className={styles.detail}>
                  <span className={styles.bold}>Hotels:</span>{" "}
                  <span>
                    {user.user_hotel
                      .map((uh) => uh.hotels?.code)
                      .filter((code) => code) // Filter out any falsy values
                      .join(", ")}
                  </span>
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Role:</span>{" "}
                  <span className={styles.capitalize}>{user.role_id.role}</span>
                </li>
                <li className={styles.detail}>
                  <span className={styles.bold}>Status:</span>{" "}
                  <span className={styles.capitalize}>
                    {user.active && user.last_sign_in
                      ? "Active"
                      : user.active && !user.last_sign_in
                      ? "Inactive"
                      : "Blocked"}
                  </span>
                </li>
              </ul>

              <div className={styles.fn_btns}>
                <button
                  onClick={() => navigate("/users")}
                  title="Return to user list"
                  className="neutral_btn"
                >
                  Return to user list
                </button>
                <button onClick={() => navigate("edit")}>Edit this user</button>
                <button
                  onClick={() => {
                    if (
                      confirm(`Do you really want to delete ${user.full_name}?`)
                    ) {
                      deleteUser();
                    }
                  }}
                >
                  Delete this user
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default User;
