// Imports
import Logo from "../../ui/elements/custom/Logo";
import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";
import { IoBarChartSharp } from "react-icons/io5";
import { HiUsers } from "react-icons/hi";
import { MdHotel } from "react-icons/md";
import { LuSettings2 } from "react-icons/lu";
import { useUser } from "../../../auth/userContext";
// import useAuth from "../../../auth/useAuth";
import UserDropdown from "../../ui/elements/custom/UserDropDown";

function Header() {
  const { userProfile } = useUser();

  // Define role levels
  const adminLevelRoles = [
    "admin",
    "internal admin",
    "superadmin",
    "developer",
  ];
  const internalAdminLevelRoles = ["internal admin", "superadmin", "developer"];
  const superadminLevelRoles = ["superadmin", "developer"];

  return (
    <>
      <header>
        <a href="/">
          <Logo src="/images/logo-red.png" alt="BENS logo" />
        </a>

        <nav>
          <menu>
            <NavLink
              to="/"
              className={(ActiveStatus) =>
                ActiveStatus.isActive ? styles.active : ""
              }
            >
              <IoBarChartSharp /> Dashboard
            </NavLink>
            {adminLevelRoles.includes(userProfile?.role_id.role) && (
              <NavLink
                to="/users"
                className={(ActiveStatus) =>
                  ActiveStatus.isActive ? styles.active : ""
                }
              >
                <HiUsers /> Users
              </NavLink>
            )}
            {superadminLevelRoles.includes(userProfile?.role_id.role) && (
              <NavLink
                to="/hotels"
                className={(ActiveStatus) =>
                  ActiveStatus.isActive ? styles.active : ""
                }
              >
                <MdHotel /> Hotels
              </NavLink>
            )}
          </menu>{" "}
        </nav>
        <nav>
          <div className={styles.user}>
            {superadminLevelRoles.includes(userProfile?.role_id.role) && (
              <NavLink
                to="/settings"
                className={(ActiveStatus) =>
                  ActiveStatus.isActive ? styles.active : ""
                }
                title="Application settings"
              >
                <LuSettings2 /> Settings
              </NavLink>
            )}

            <UserDropdown user={userProfile} />
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
