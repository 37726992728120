import { Routes, Route } from "react-router-dom";
import Analytics from "./pages/Analytics.jsx";
import Users from "./pages/Users";
import Hotels from "./pages/Hotels";
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import NewUser from "./pages/NewUser";
import User from "./pages/User";
import EditUser from "./pages/EditUser";
import NewHotel from "./pages/NewHotel";
import Hotel from "./pages/Hotel";
import EditHotel from "./pages/EditHotel";
import Login from "./pages/auth/Login";
import ChangePassword from "./pages/auth/ChangePswd";
import Settings from "./pages/Settings";
import SignUp from "./pages/auth/SignUp.jsx";
import ProtectedRoute from "./pages/routes/ProtectedRoute.jsx";
import OpenRoute from "./pages/routes/OpenRoute.jsx";
import { UserProvider } from "./auth/userContext.jsx";

function App() {
  return (
    <>
      <UserProvider>
        <main>
          <Routes>
            <Route
              path="/login"
              element={
                <OpenRoute>
                  <Login />
                </OpenRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <OpenRoute>
                  <SignUp />
                </OpenRoute>
              }
            />
            <Route
              path="/password"
              element={
                <OpenRoute>
                  <ChangePassword />
                </OpenRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>
              }
            />

            <Route
              path="/analytics"
              element={
                <ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "internal admin",
                    "superadmin",
                    "developer",
                  ]}
                >
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users/new"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "internal admin",
                    "superadmin",
                    "developer",
                  ]}
                >
                  <NewUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users/:id"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "internal admin",
                    "superadmin",
                    "developer",
                  ]}
                >
                  <User />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users/:id/edit"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "admin",
                    "internal admin",
                    "superadmin",
                    "developer",
                  ]}
                >
                  <EditUser />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hotels"
              element={
                <ProtectedRoute allowedRoles={["superadmin", "developer"]}>
                  <Hotels />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hotels/new"
              element={
                <ProtectedRoute allowedRoles={["superadmin", "developer"]}>
                  <NewHotel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hotels/:id"
              element={
                <ProtectedRoute allowedRoles={["superadmin", "developer"]}>
                  <Hotel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/hotels/:id/edit"
              element={
                <ProtectedRoute allowedRoles={["superadmin", "developer"]}>
                  <EditHotel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute allowedRoles={["superadmin", "developer"]}>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>
      </UserProvider>
    </>
  );
}

export default App;
