import { useState, useRef, useEffect } from "react";
import styles from "./UserDropDown.module.css";
import { MdWork } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { NavLink } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import useAuth from "../../../../auth/useAuth";

const UserDropdown = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { logoutUser } = useAuth();

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    await logoutUser();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.userDropdown}>
      <span
        onClick={handleToggleDropdown}
        className={`${styles.userName} ${isOpen && styles.dropDownOpen}`}
      >
        {user.full_name} <BsChevronDown />
      </span>

      {isOpen && (
        <div className={styles.dropdownMenu} ref={dropdownRef}>
          <div className={styles.userDetails}>
            <h3 className={styles.name}>{user.full_name}</h3>
            <p className={styles.email}>{user.email}</p>
          </div>
          <hr />
          <div className={styles.role}>
            <p className={styles.user_role}>
              <MdWork />
              {user.role_id.role}
            </p>
          </div>
          <hr />
          <div className={styles.hotel}>
            <p className={styles.hotel_name}>
              <h4>Hotels</h4>
              {user.role_id.role === "superadmin" ||
              user.role_id.role === "developer" ? (
                "All Access"
              ) : (
                <ul className={styles.hotel_list}>
                  {user.user_hotel.map((hotel) => (
                    <li key={hotel.hotels.id}>{hotel.hotels.name}</li>
                  ))}
                </ul>
              )}
            </p>
          </div>
          <hr />
          <div className={styles.logout}>
            <NavLink
              to="/profile"
              className={(ActiveStatus) =>
                ActiveStatus.isActive ? styles.active : ""
              }
              title="My profile"
            >
              <FaRegUser /> My Profile
            </NavLink>
            <button
              className={styles.logout_btn}
              title="Sign out"
              onClick={(e) => {
                handleLogout(e);
              }}
            >
              <LuLogOut /> Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
