import styles from "./Profile.module.css";
import { toast } from "react-toastify";
import { useState } from "react";
import { supabase } from "../supabase/client";
import { format } from "date-fns";
import CheckPassword from "../utils/CheckPassword";
import PasswordChanged from "../emails/PasswordChanged";
import { render } from "@react-email/components";
import SendEmail from "../utils/SendEmail";
import { useUser } from "../auth/userContext";
import useAuth from "../auth/useAuth";
import formatDateForUser from "../utils/formatDateForUser";

function Profile() {
  const { userProfile } = useUser();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { logoutUser } = useAuth();

  const changePassword = async () => {
    if (!password || !confirmPassword) {
      toast.error("Password and confirm password are required!");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password and confirm password fields must match!");
      return;
    }

    if (
      !CheckPassword(password, {
        minLength: 8,
        lowercase: true,
        uppercase: true,
        numeric: true,
        special: true,
      })
    ) {
      toast.error(
        "Passwords must be at least 8 characters long, and contain lowercase, uppercase, numeric and special characters."
      );
      return;
    }

    try {
      const { data, error } = supabase.auth.updateUser({
        password: password.trim(),
      });

      if (error) {
        throw new Error("Unable to change user password: ", error);
      }

      const html = render(
        <PasswordChanged firstName={userProfile.first_name} />
      );
      SendEmail({
        to: userProfile.email,
        subject: "Your Password Has Been Changed",
        html: html,
      });

      toast.success(
        "Your password has been successfully changed! Please login again."
      );

      // Log the user out

      try {
        await logoutUser();
      } catch (error) {
        toast.error("Failed to sign out user");
      }
    } catch (error) {
      console.error(error);
      toast.error(() => {
        <>
          <p>Changing your password failed!</p>
          <p className={styles.toast_info}>Reason: {error.message}</p>
        </>;
      });
    }
  };

  const handlePswdChange = (e) => {
    e.preventDefault();
    changePassword();
  };

  return (
    <>
      <div className={styles.main_container}>
        <h3 className={styles.page_title}>My Profile</h3>

        {userProfile && (
          <>
            <div className={styles.form_container}>
              <>
                <div className={styles.user_details}>
                  <h3 className={styles.bold}>Details</h3>
                  <ul className={styles.user_details}>
                    <li className={styles.detail}>
                      <span className={styles.bold}>Name:</span>{" "}
                      <span>{userProfile.full_name}</span>
                    </li>
                    <li className={styles.detail}>
                      <span className={styles.bold}>Email:</span>{" "}
                      <span>{userProfile.email}</span>
                    </li>
                    <li className={styles.detail}>
                      <span className={styles.bold}>Created:</span>{" "}
                      <span>{formatDateForUser(userProfile.created_at)}</span>
                    </li>
                    <li className={styles.detail}>
                      <span className={styles.bold}>Last Login:</span>{" "}
                      <span>{formatDateForUser(userProfile.last_sign_in)}</span>
                    </li>
                    <li className={styles.detail}>
                      <span className={styles.bold}>Last Active:</span>{" "}
                      <span>
                        {userProfile.updated_at
                          ? formatDateForUser(userProfile.updated_at)
                          : "Never"}
                      </span>
                    </li>

                    <li className={styles.detail}>
                      <span className={styles.bold}>Status:</span>{" "}
                      <span className={styles.capitalize}>
                        {userProfile.active ? "active" : "blocked"}
                      </span>
                    </li>
                    <li className={styles.detail}>
                      <span className={styles.bold}>Hotels:</span>{" "}
                      <span>
                        {userProfile.user_hotel
                          .map((uh) => uh.hotels.code)
                          .join(", ")}
                      </span>
                    </li>
                    <li className={styles.detail}>
                      <span className={styles.bold}>Role:</span>{" "}
                      <span className={styles.capitalize}>
                        {userProfile.role_id.role}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className={styles.divider}></div>

                <div className={styles.change_password}>
                  <h3 className={styles.bold}>Change Password</h3>
                  <p className={styles.info}>
                    Passwords must be at least 8 characters long, and contain
                    lowercase, uppercase, numeric and special characters.
                  </p>
                  <form
                    onSubmit={(e) => {
                      handlePswdChange(e);
                    }}
                  >
                    <label htmlFor="password" className={styles.labels}>
                      New password
                    </label>
                    <input
                      required
                      type="password"
                      placeholder="********"
                      id="password"
                      className={styles.textboxes}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                    <label htmlFor="password2" className={styles.labels}>
                      Repeat new password
                    </label>
                    <input
                      required
                      type="password"
                      placeholder="********"
                      id="password2"
                      className={styles.textboxes}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    ></input>
                    <button className={styles.float_right}>
                      Update password
                    </button>
                  </form>
                </div>
              </>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Profile;
