import styles from "./Scorecard.module.css";
import compactNum from "../../../../utils/CompactNumber";
import { RiHotelBedLine } from "react-icons/ri";
import { FaDollarSign } from "react-icons/fa";
import { MdOutlineToday } from "react-icons/md";
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { multiply, divide, subtract } from "mathjs";

function Scorecard({
  title,
  value,
  compareValue,
  prefix,
  suffix,
  icon,
  showValue,
}) {
  const gainOrLossPcnt = () => {
    let percentageDifference;

    if (compareValue === 0) {
      if (value > 0) {
        percentageDifference = 100;
      } else {
        percentageDifference = 0; // No change
      }
    } else {
      percentageDifference = multiply(
        divide(subtract(value, compareValue), compareValue),
        100
      ).toFixed(1);
    }

    return percentageDifference;
  };

  function titleIcon(iconString) {
    if (iconString === "hotel") return <RiHotelBedLine />;
    if (iconString === "money") return <FaDollarSign />;
    if (iconString === "calendar") return <MdOutlineToday />;
  }

  return (
    <>
      <div className={styles.scorecard}>
        <h4 className={styles.title}>
          {titleIcon(icon)}
          {title}
        </h4>
        <span className={styles.value}>
          {prefix}
          {compactNum(value, 2)}
          {suffix}
        </span>
        <span className={styles.compare_value}>
          <span
            className={`${styles.compare} ${
              value > compareValue ? styles.gain : styles.loss
            }`}
          >
            {value > compareValue ? (
              <FaArrowUp className={styles.icon} />
            ) : (
              <FaArrowDown className={styles.icon} />
            )}
            &nbsp;
            {gainOrLossPcnt()}%
          </span>{" "}
          <span className={styles.info}>
            {" "}
            &nbsp; vs. same period last month
          </span>
        </span>
      </div>
    </>
  );
}

export default Scorecard;
