import Header from "../components/sections/main/Header";
import styles from "./NotFound.module.css";
import { BsFillSignStopFill } from "react-icons/bs";

function NotFound() {
  return (
    <>
      <Header />
      <div className={styles.main_container}>
        <h3 className={styles.page_title}>
          <BsFillSignStopFill />
          Not found
        </h3>
        <p>
          The resource you are looking for does not exist or do not have the
          permission to access it.
        </p>
        <p>Please select one of the available menu options above.</p>
      </div>
    </>
  );
}

export default NotFound;
