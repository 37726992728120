import {
  Bar,
  BarChart,
  XAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
  YAxis,
  Legend,
  CartesianGrid,
} from "recharts";
import { differenceInDays, format } from "date-fns";
import { RxDotFilled } from "react-icons/rx";
import styles from "./TimelineChart.module.css";
import { useState, useMemo } from "react";
import { mean, sum } from "mathjs";
import compactNum from "../../../../utils/CompactNumber";

// Helper function to calculate the selected metric safely
const calculateMetric = (data, metric) => {
  if (!data || data.length === 0) return 0;

  const metricKey =
    metric === "Occupancy"
      ? "occupancy"
      : metric === "ADR"
      ? "ADR"
      : metric === "Total Revenue"
      ? "totalRevenue"
      : "revPAR";

  // Filter out invalid values (undefined, null, NaN)
  const validValues = data
    .map((record) => record[metricKey])
    .filter((value) => value !== undefined && value !== null && !isNaN(value));

  if (metric === "Total Revenue") {
    return "$" + compactNum(sum(validValues), 2);
  }

  return metric === "Occupancy"
    ? compactNum(mean(validValues), 2) + "%"
    : "$" + compactNum(mean(validValues), 2);
};

// Map the selected metric to corresponding fields
const mapMetricToFieldName = (metric) => {
  switch (metric) {
    case "Occupancy":
      return ["occupancy", "prevOccupancy"];
    case "ADR":
      return ["ADR", "prevADR"];
    case "Total Revenue":
      return ["totalRevenue", "prevTotalRevenue"];
    case "RevPAR":
      return ["revPAR", "prevRevPAR"];
    default:
      return ["occupancy", "prevOccupancy"];
  }
};

// Get max value based on the selected metric
const getMaxValue = (metric, maxValues) => {
  switch (metric) {
    case "Occupancy":
      return 100;
    case "ADR":
      return maxValues?.maxADR || 0;
    case "Total Revenue":
      return maxValues?.maxTotalRevenue || 0;
    case "RevPAR":
      return maxValues?.maxRevPAR || 0;
    default:
      return 100;
  }
};

function TimelineChart({ data, selectedDate, targetDate, maxValues }) {
  const [selectedMetric, setSelectedMetric] = useState("Occupancy");

  // Memoize the calculation of the metric value to improve performance
  const metricValue = useMemo(
    () => calculateMetric(data, selectedMetric),
    [data, selectedMetric]
  );

  // Memoize max value to avoid recalculating unnecessarily
  const maxValue = useMemo(
    () => getMaxValue(selectedMetric, maxValues),
    [selectedMetric, maxValues]
  );

  // Memoize the bar sizes to avoid recalculating on every render
  const barSize = useMemo(() => {
    const screenWidth = window.innerWidth;
    const defaultSize = 20 * (screenWidth / 1200); // Adjust this calculation as needed
    return {
      currentYear: defaultSize,
      previousYear: defaultSize + 3,
    };
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.chart_title}>
          <h4 className={styles.metric}>{selectedMetric}</h4>
          <h2 className={styles.avg_value}>{metricValue}</h2>
          <p className={styles.date_info}>
            as at {selectedDate && format(selectedDate, "PPP")} <RxDotFilled />
            <span className={styles.days_to_end}>
              {differenceInDays(targetDate, selectedDate)} &nbsp;days to end
              date
            </span>
          </p>
        </div>
        <div className={styles.chart_filter}>
          <select
            id="metrics"
            name="metrics"
            className={styles.textboxes}
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
          >
            <option value="Occupancy">Occupancy</option>
            <option value="ADR">ADR</option>
            <option value="Total Revenue">Total revenue</option>
            <option value="RevPAR">RevPAR</option>
          </select>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          data={data}
          margin={{ top: 15, right: 30, left: 15, bottom: 60 }}
        >
          <CartesianGrid vertical={false} strokeDasharray="0.2" />
          <Legend
            wrapperStyle={{
              paddingBottom: "40px",
            }}
            align="right"
            verticalAlign="top"
            iconType="circle"
          />
          <Tooltip
            formatter={(value) =>
              selectedMetric === "Occupancy"
                ? compactNum(value, 2) + "%"
                : "$" + compactNum(value, 2)
            }
            labelFormatter={(value) =>
              format(Date.parse(value), "eeee dd MMMM yyyy")
            }
          />
          <YAxis
            dataKey={mapMetricToFieldName(selectedMetric)[0]}
            axisLine={false}
            tickLine={false}
            tickMargin={10}
            tickFormatter={(value) =>
              selectedMetric === "Occupancy"
                ? value + "%"
                : "$" + compactNum(value, 0)
            }
            domain={[0, maxValue]}
          />
          <XAxis
            dataKey="date"
            xAxisId="value"
            tickFormatter={(value) => format(Date.parse(value), "eee dd-MM")}
            name="Date"
            angle={325}
            textAnchor="end"
            dy={5}
            fontSize="0.9rem"
            type="category"
          />
          <XAxis dataKey="prevDate" xAxisId="prev" hide name="Date" />
          <Bar
            dataKey={mapMetricToFieldName(selectedMetric)[1]}
            xAxisId="prev"
            fill="var(--color-blue)"
            opacity={0.3}
            name={`${selectedMetric} (previous month)`}
            animationDuration={2500}
            barSize={barSize.previousYear}
          >
            <LabelList
              dataKey={mapMetricToFieldName(selectedMetric)[1]}
              position="top"
              fontSize="0.8rem"
              fill="var(--color-blue)"
              formatter={(value) =>
                selectedMetric === "Occupancy"
                  ? compactNum(value, 1) + "%"
                  : "$" + compactNum(value, 1)
              }
            />
          </Bar>
          <Bar
            dataKey={mapMetricToFieldName(selectedMetric)[0]}
            xAxisId="value"
            fill="var(--color-red)"
            opacity={0.3} //0.6
            name={selectedMetric}
            animationDuration={1500}
            barSize={barSize.currentYear}
            style={{ marginLeft: `-${barSize.currentYear / 2}px` }}
          >
            <LabelList
              dataKey={mapMetricToFieldName(selectedMetric)[0]}
              position="top"
              fontSize="0.8rem"
              fill="var(--color-red)"
              formatter={(value) =>
                selectedMetric === "Occupancy"
                  ? compactNum(value, 1) + "%"
                  : "$" + compactNum(value, 1)
              }
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default TimelineChart;
