import styles from "./Settings.module.css";

function Settings() {
  return (
    <>
      <div className={styles.main_container}>
        <h3 className={styles.page_title}>Application Settings</h3>
        <div className={styles.form_container}>Content here</div>
      </div>
    </>
  );
}

export default Settings;
